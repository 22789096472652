import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';

import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import Home from './pages/Home/Home';
import Profile from './pages/Profile/Profile';
import Users from './pages/Users/Users';
import Admin from './pages/Admin/Admin';
import NotFound from './pages/NotFound/NotFound';
import Main from './components/grid/main.tsx';
import Loader from './components/Loader/Loader';
import styled from 'styled-components';
import Stripe from './pages/Stripe/Stripe';
import { logInUserWithOauth, loadMe } from './store/actions/authActions';
import {loadStripe} from '@stripe/stripe-js';

let Container = styled.div`background-color:transparent;height:100%`
//

const App = ({ logInUserWithOauth, auth, loadMe }) => {
  useEffect(() => {
    loadMe();
  }, [loadMe]);


  useEffect(() => {
    if (window.location.hash === '#_=_') window.location.hash = '';

    const cookieJwt = Cookies.get('x-auth-cookie');
    if (cookieJwt) {
      Cookies.remove('x-auth-cookie');
      logInUserWithOauth(cookieJwt);
    }
  }, []);

  useEffect(() => {
    if (!auth.appLoaded && !auth.isLoading && auth.token && !auth.isAuthenticated) {
      loadMe();
    }

  }, [auth.isAuthenticated, auth.token, loadMe, auth.isLoading, auth.appLoaded]);
  
  console.log("BAKJXBNAJXBNAKXNAKJNX\n\n\n")
  return (
    <Container>
      
      {auth.appLoaded ? (
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route path="/users" component={Users} />
          <Route exact path="/grid" component={Main} />
          <Route path="/notfound" component={NotFound} />
          <Route path="/admin" component={Admin} />
          <Route path="/stripe" component={Stripe} />
          <Route exact path="/:username" component={Profile} />
          <Route path="/register" component={Register} />
        </Switch>
      ) : (
        <div />
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  state:state
});

export default compose(connect(mapStateToProps, { logInUserWithOauth, loadMe }))(App);
